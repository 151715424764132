export const terms_en = `
# Terms of Use & Data protection

GetSignalz is a service provided by Mapaliro UG (limited liability). Please read these terms carefully. These apply to all services, ancillary services, programs, information, promotions and products made available to you on the GetSignalz website or in our other publications.

## Definitions

- **Customer** means the legal or natural person who has entered into a contract with Mapaliro UG, which entitles this person to receive the services of GetSignalz.
- **Personal data** is any information relating to an identified or identifiable natural person, such as B. name, e-mail address, employer, connection data, access data or communication content.
- **Mapaliro UG** means Mapaliro UG (haftungsbeschränkt (limited liability)), Porvoostr. 6, 91550 Dinkelsbühl, Germany, as the provider of the services.
- **GetSignalz** means the web-based platform that provides access to its web-based platform that presents and reports computer generated performance analysis of security charts
- **Services** means the online services related to GetSignalz that Mapaliro UG makes available to a customer pursuant to the relevant contract between Mapaliro UG and that customer.
- **User** means the natural person using GetSignalz.
- Otherwise, the definitions of terms according to Art. 4 GDPR apply.

## **Copyrights**

Texts, images, graphics and the layout of this website are subject to copyright. Violations of this will be prosecuted under both civil and criminal law. Reproduction of all or part of the content of this website is only permitted with the prior written consent of Mapaliro UG. Individual use does not entitle the transfer of content to third parties.

## **Disclaimer**

Mapaliro UG is not liable for any loss or damage of any kind arising from the use of this website or the inability to access it, whether due to system maintenance or technical malfunctions. Mapaliro UG assumes no guarantee or liability for the completeness, accuracy and timeliness of the information provided on its website. It is your responsibility to independently verify the content made available to you through this website and our services.
Mapaliro UG is not responsible and assumes no liability for websites referred to by hyperlinks. It does not adopt their content as its own.

## Communication

Mapaliro UG is not liable for claims that could be based on communication by e-mail. By using our service or communicating with our company via email, you accept these terms. It is your responsibility to ensure that information and content that you download or otherwise select onto your systems is checked for viruses or other destructive properties.

## Terms of Use for Financial Information

The information on the websites of Mapaliro UG and publications published in our name are intended exclusively for private use by natural persons residing in Germany.

Mapaliro UG does not guarantee the suitability or usability of the information on these websites or in our publications for use in other locations and/or for other services and ancillary services. Users who access this website from other locations or take note of our publications outside of Germany do so on their own initiative and at their own risk. Mapaliro UG makes no representation or warranty that this website or the information contained therein comply with the laws outside of Germany.
In general, the stock market and economic information contained on this website and all publications on our behalf are for informational purposes only and do not constitute an individual investment recommendation (investment advice) or an offer to buy or sell securities, derivatives or other financial instruments. In particular, they do not represent an invitation to buy or sell securities, derivatives or other financial instruments. Mapaliro UG assumes no liability for the correctness and completeness of the information. Estimates, opinions and ratings reflect solely the opinion of the respective author at the time of creation. Information on the past performance of a product does not allow for forecasts for the future.

The opinions, assessments and views expressed in our publications are subject to change without notice.

The prices of products (shares, securities, derivatives or other financial instruments) can go down as well as up. Yields (dividends, interest, etc.) cannot be assured or guaranteed either. You may not get back the full amount invested. Exchange rate fluctuations can also affect the value of foreign investments.

In the case of equity investments in particular, there is a risk of loss up to and including total loss.

# Data protection

Mapaliro UG (limited liability) offers customers access to its web-based platform, which displays and provides information about computer-generated performance analyzes of stock charts ("GetSignalz"). This data protection declaration informs users about the collection and processing of personal data in connection with the use of GetSignalz.

## Processing of personal data

When processing personal data in connection with the use of GetSignalz by a user, Mapaliro UG observes the requirements of the EU General Data Protection Regulation (GDPR), the Federal Data Protection Act (BDSG) and other applicable laws.
Unless otherwise required by applicable law, Mapaliro UG processes personal data only to the extent necessary for the provision of the Services as agreed in the contract between GetSignalz and the customer. This includes in particular the processing of the following personal data for the following purposes:

### Personal Data

1. Personal data provided by a customer or a user during the invitation or registration process in relation to GetSignalz, including the user's last name, first name, email address, company name and function or, where applicable, a user's profile picture .
2. Correspondence and other content shared by a user when communicating with other users of GetSignalz or otherwise using GetSignalz.
3. Correspondence with a GetSignalz user.
4. Details about a user's access and use of GetSignalz and information collected through cookies, including but not limited to, the date and time of a user's activities on GetSignalz, session duration, and amount of data transferred.

### Purposes

User and access management

- Registration of users on GetSignalz
- Assignment of users to customers and their business partners
- Management of registration and access data

security reasons

- Management of the authorization system;
- Limiting access to customer-related information and content through authentication measures based on the registration process or one-time access data sent to users via email;

collaboration features

- Provide notification capabilities about user activity on GetSignalz via email or instant messaging
- Support communication between users
- Provide status tracking of process step tasks

Measuring and improving the Services

- Anonymize personal data for the purpose of evaluating, analyzing and improving the Services based on anonymized data (e.g. enabling recommendation services for the benefit of customers)

## Transmission of personal data

In order to provide the Services to the Customer, personal information relating to users of GetSignalz may be shared with customers or with third parties who assist Mapaliro UG in providing the Services, such as: B. Technology service providers and other subcontractors acting on behalf of Mapaliro UG. In addition, personal data may be transferred to third parties such as law enforcement and regulatory authorities where required by applicable law. For example, a GetSignalz user's first name, last name, email, and password are managed by SaaS provider "auth0".

## Cookies

GetSignalz uses cookies to recognize users when accessing GetSignalz and to enable users to use its features such as Cookies are text files containing small amounts of information that are stored on a user's device. GetSignalz only uses cookies that are essential to provide the Services and enable users to use its functions.

GetSignalz currently only uses the following necessary cookies:

- appSession [z](http://app.signalznow.de/)ur authentication in [app.signalznow.de](http://app.signalznow.de/) (permanent)
- amplitude_id_ba62b82db20ab8dc707875f4534366c1sentry.io for error tracking and logging by [sentry.io](http://sentry.io/) (permanent)
- __stripe_mid for error tracking and logging by [sentry.io](http://sentry.io/) (permanent)
- _ga for error tracking and logging by [sentry.io](http://sentry.io/) (permanent)
- gsID for error tracking and logging by [sentry.io](http://sentry.io/) (session)
- anonId for error tracking and logging by [sentry.io](http://sentry.io/) (session)
- ai_authUser [z](http://app.signalznow.de/)ur usage statistics in [app.signalznow.de](http://app.signalznow.de/) (session)
- ai_session for usage statistics in [app.signalznow.de](http://app.signalznow.de/) (permanent)
- ai_user [z](http://app.signalznow.de/)ur usage statistics in [app.signalznow.de](http://app.signalznow.de/) (permanent)

Users can configure their web browser to block necessary cookies, which may mean that they may not be able to use GetSignalz's features as intended.

## Data retention

Personal data will be retained by GetSignalz for the period agreed in our contract with the customer, unless otherwise required or permitted by applicable data protection laws.

## Security of data processing

GetSignalz has appropriate technical and organizational measures in accordance with the requirements of Art. 32 GDPR to protect users' personal data. GetSignalz will ensure that all of its employees and other persons who are authorized to process the personal data have committed themselves to confidentiality or are subject to an appropriate legal obligation of confidentiality.

## Responsible for personal data

The controller within the meaning of the GDPR in relation to the processing of a user's personal data in connection with the provision of the Services, including a user's use of GetSignalz, is the customer who authorized the relevant user to access GetSignalz.

## Legal Basis

Customer is responsible for ensuring that the processing of personal data in connection with the provision of the Services to that customer has a legal basis in accordance with applicable data protection laws. The specific legal basis for the data processing (e.g. performance of a contract or legitimate interest of the customer to use GetSignalz for internal business purposes) is determined by the customer.

## Rights of the data subject

Users are entitled to certain rights in relation to the processing of their personal data. In particular and subject to the legal requirements, users may be entitled

- Obtain confirmation as to whether or not personal data is being processed, and if so, to (i) obtain information about the processing, including the legal basis, and (ii) access that personal data;
- Request the correction of inaccurate personal data;
- Request erasure of personal data;
- Request restriction of data processing;
- request data portability in relation to personal data that a user has actively provided, and;
- Object to further processing of personal data for reasons arising from the particular situation of the user.

Requests to exercise the rights must be addressed to the respective customer (controller) for whom GetSignalz is processing the personal data of the respective user. In addition, users can be addressed at any time via [info@mapaliro.com](mailto:info@mapaliro.com) with data protection-related questions, complaints or suggestions.
`

export const terms_de = `
# Nutzungsbedingungen & Datenschutzerklärung

GetSignalz ist ein Service der Mapaliro UG (haftungsbeschränkt). Bitte lesen Sie diese Bedingungen sorgfältig durch. Diese finden Anwendung auf alle Dienstleistungen, Nebendienstleistungen, Programme, Informationen, Werbemaßnahmen und Produkte, die auf der Website von GetSignalz oder in unseren sonstigen Veröffentlichungen für Sie bereitgestellt werden.

## Definitionen

- **Kunde** bezeichnet die juristische oder natürliche Person, die mit Mapaliro UG einen Vertrag abgeschlossen hat, der diese Person berechtigt, die Dienste von GetSignalz zu erhalten.
- **Personenbezogene Daten** sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen, wie z. B. Name, E-Mail-Adresse, Arbeitgeber, Verbindungsdaten, Zugriffsdaten oder Kommunikationsinhalte.
- **Mapaliro UG** bedeutet Mapaliro UG (haftungsbeschränkt), Porvoostr. 6, 91550 Dinkelsbühl, Deutschland, als Anbieter der Dienste.
- **GetSignalz** bezeichnet die webbasierte Plattform, die Zugriff auf ihre webbasierte Plattform bietet, die computergenerierte Leistungsanalysen von Aktienscharts präsentiert und darüber informiert
- **Service(s)** bezeichnet die Online-Dienste im Zusammenhang mit GetSignalz, die Mapaliro UG einem Kunden gemäß dem entsprechenden Vertrag zwischen Mapaliro UG und diesem Kunden zur Verfügung stellt.
- **Benutzer/Nutzer** bezeichnet die natürliche Person, die GetSignalz verwendet.
Im Übrigen gelten die Begriffsbestimmungen gemäß Art. 4 DSGVO gilt.

## **Urheberrechte**

Texte, Bilder, Grafiken sowie Layout dieser Website unterliegen dem Urheberrecht. Verstöße hiergegen werden sowohl zivil- als auch strafrechtlich verfolgt. Die Vervielfältigung des gesamten oder eines Teils des Inhalts dieser Website ist nur mit vorheriger schriftlicher Zustimmung der Mapaliro UG (haftungsbeschränkt) gestattet. Die individuelle Nutzung berechtigt nicht zur Weitergabe der Inhalte an Dritte.

## **Haftungsausschluss**

Die Mapaliro UG (haftungsbeschränkt) haftet nicht für Verluste oder Schäden jeglicher Art, die durch die Nutzung dieser Website oder die Unmöglichkeit des Zugriffs darauf entstehen, sei es aufgrund von Systemwartungen oder technischen Störungen. Die Mapaliro UG (haftungsbeschränkt) übernimmt keine Garantie oder Haftung für die Vollständigkeit, Richtigkeit und Aktualität der auf ihren Webseiten bereitgestellten Informationen. Es liegt in Ihrer Verantwortung, die Inhalte, die Ihnen über diese Website und unsere Dienste zur Verfügung gestellt werden, unabhängig zu überprüfen. Die Mapaliro UG (haftungsbeschränkt) ist nicht verantwortlich und übernimmt keine Haftung für Websites, auf die durch Hyperlinks verwiesen wird. Sie macht sich deren Inhalt nicht zu eigen.

## Kommunikation

Eine Haftung unseres Unternehmens für Ansprüche, die aufgrund einer Kommunikation per E-Mail begründet werden könnten, ist ausgeschlossen. Indem Sie unseren Service nutzen oder mit unserem Unternehmen per E-Mail kommunizieren, akzeptieren Sie diese Bedingungen. Es liegt in Ihrer Verantwortung sicherzustellen, dass Informationen und Inhalte, die Sie auf Ihre Systeme herunterladen oder auf andere Weise auswählen, auf Viren oder andere zerstörerische Eigenschaften überprüft werden.

## Nutzungsbedingungen für Finanzinformationen

Die Informationen auf den Webseiten der Mapaliro UG (haftungsbeschränkt) sowie in unserem Namen veröffentlichte Publikationen sind ausschließlich für den privaten Gebrauch durch natürliche Personen mit Wohnsitz in Deutschland bestimmt.

Die Mapaliro UG (haftungsbeschränkt) übernimmt keine Gewährleistung hinsichtlich der Eignung oder Verwendbarkeit der Informationen auf diesen Webseiten oder in unseren Veröffentlichungen zur Verwendung an anderen Orten und/oder für andere Dienste und Nebenleistungen. Benutzer, die von anderen Orten aus auf diese Website zugreifen oder unsere Veröffentlichungen außerhalb Deutschlands zur Kenntnis nehmen, tun dies auf eigene Initiative und eigenes Risiko. Die Mapaliro UG (haftungsbeschränkt) gibt keine Zusicherung oder Gewährleistung dafür ab, dass diese Website oder die darin enthaltenen Informationen den Gesetzen außerhalb Deutschlands entsprechen.
Generell dienen die auf dieser Website enthaltenen Börsen- und Wirtschaftsinformationen sowie alle Veröffentlichungen in unserem Namen nur zu Informationszwecken und stellen keine individuelle Anlageempfehlung (Anlageberatung) oder ein Angebot zum Kauf oder Verkauf von Wertpapieren, Derivaten dar oder andere Finanzinstrumente. Sie stellen insbesondere keine Aufforderung zum Kauf oder Verkauf von Wertpapieren, Derivaten oder anderen Finanzinstrumenten dar. Die Mapaliro UG (haftungsbeschränkt) übernimmt keine Gewähr für die Richtigkeit und Vollständigkeit der Informationen. Schätzungen, Meinungen und Wertungen geben ausschließlich die Meinung des jeweiligen Autors zum Zeitpunkt der Erstellung wieder. Angaben zur vergangenen Wertentwicklung eines Produkts lassen keine Prognosen für die Zukunft zu.

Die in unseren Veröffentlichungen geäußerten Meinungen, Einschätzungen und Ansichten können ohne Vorankündigung geändert werden. Die Preise von Produkten (Aktien, Wertpapiere, Derivate oder andere Finanzinstrumente) können sowohl steigen als auch fallen. Auch Renditen (Dividenden, Zinsen etc.) können nicht zugesichert oder garantiert werden. Möglicherweise erhalten Sie nicht den vollen angelegten Betrag zurück. Wechselkursschwankungen können sich zusätzlich auf den Wert ausländischer Anlagen auswirken. Insbesondere bei Aktienanlagen besteht ein Verlustrisiko bis hin zum Totalverlust.

# Datenschutzerklärung

Die Mapaliro UG (haftungsbeschränkt) bietet Kunden Zugang zu ihrer webbasierten Plattform, die computergenerierte Performanceanalysen von Aktiencharts („GetSignalz“) darstellt und darüber informiert. Diese Datenschutzerklärung informiert Nutzer über die Erhebung und Verarbeitung personenbezogener Daten im Zusammenhang mit der Nutzung von GetSignalz.

## Verarbeitung personenbezogener Daten

Bei der Verarbeitung personenbezogener Daten im Zusammenhang mit der Nutzung von GetSignalz durch einen Nutzer beachtet Mapaliro UG die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO), des Bundesdatenschutzgesetzes (BDSG) und anderer anwendbarer Gesetze.
Sofern nicht anders durch geltendes Recht vorgeschrieben, verarbeitet Mapaliro UG personenbezogene Daten nur in dem Umfang, der für die Erbringung der Dienstleistungen erforderlich ist, wie im Vertrag zwischen GetSignalz und dem Kunden vereinbart. Dies umfasst insbesondere die Verarbeitung der folgenden personenbezogenen Daten zu folgenden Zwecken:

### Persönliche Daten

1. Personenbezogene Daten, die ein Kunde oder ein Benutzer während des Einladungs- oder Registrierungsprozesses in Bezug auf GetSignalz bereitstellt, einschließlich des Namens, des Vornamens, der E-Mail-Adresse, des Firmennamens und der Funktion des Benutzers oder gegebenenfalls des Profilbilds eines Benutzers.
2. Korrespondenz und andere Inhalte, die von einem Benutzer geteilt werden, wenn er mit anderen Benutzern von GetSignalz kommuniziert oder GetSignalz anderweitig nutzt.
3. Korrespondenz mit einem GetSignalz-Benutzer.
4. Details über den Zugriff und die Nutzung von GetSignalz durch einen Benutzer und Informationen, die durch Cookies gesammelt werden, einschließlich, aber nicht beschränkt auf, Datum und Uhrzeit der Aktivitäten eines Benutzers auf GetSignalz, Sitzungsdauer und übertragene Datenmenge.

### Zwecke

Benutzer- und Zugriffsverwaltung

- Registrierung von Benutzern auf GetSignalz
- Zuordnung von Benutzern zu Kunden und deren Geschäftspartnern
- Verwaltung von Registrierungs- und Zugangsdaten

Sicherheitsgründe

- Verwaltung des Berechtigungssystems;
- Beschränkung des Zugangs zu kundenbezogenen Informationen und Inhalten durch Authentifizierungsmaßnahmen basierend auf dem Registrierungsprozess oder einmaligen Zugangsdaten, die den Benutzern per E-Mail zugesandt werden;

Kollaborationsfunktionen

- Bereitstellung von Benachrichtigungsfunktionen über Aktivitäten von Benutzern auf GetSignalz per E-Mail oder Instant Messaging
- Unterstützung der Kommunikation zwischen Benutzern
- Bereitstellen der Statusverfolgung von Prozessschrittaufgaben

Messung und Verbesserung der Dienste

- Anonymisierung personenbezogener Daten zum Zweck der Bewertung, Analyse und Verbesserung der Dienste auf der Grundlage anonymisierter Daten (z. B. Ermöglichung von Empfehlungsdiensten zum Nutzen der Kunden)

## Übermittlung personenbezogener Daten

Um die Dienste für den Kunden bereitzustellen, können personenbezogene Daten in Bezug auf Benutzer von GetSignalz an Kunden oder an Dritte weitergegeben werden, die Mapaliro UG bei der Bereitstellung der Dienste unterstützen, wie z. B. Technologiedienstleister und andere Subunternehmer, die im Auftrag von Mapaliro UG handeln. Darüber hinaus können personenbezogene Daten an Dritte wie Strafverfolgungs- und Aufsichtsbehörden übermittelt werden, wenn dies nach geltendem Recht erforderlich ist. Beispielsweise werden Vorname, Nachname, E-Mail und Passwort eines GetSignalz-Benutzers vom SaaS-Anbieter „auth0“ verwaltet.

## Cookies

GetSignalz verwendet Cookies, um Benutzer beim Zugriff auf GetSignalz zu erkennen und um Benutzern die Nutzung seiner Funktionen zu ermöglichen, wie z. Cookies sind Textdateien mit kleinen Informationsmengen, die auf dem Gerät eines Benutzers gespeichert werden. GetSignalz verwendet nur Cookies, die für die Bereitstellung der Dienste unerlässlich sind und den Benutzern die Nutzung ihrer Funktionen ermöglichen.
GetSignalz verwendet derzeit nur die folgenden notwendigen Cookies:

- appSession [z](http://app.signalznow.de/)ur Authentifizierung in [app.signalznow.de](http://app.signalznow.de) (dauerhaft)
- amplitude_id_ba62b82db20ab8dc707875f4534366c1sentry.io zur Fehlerverfolgung und -protokollierung durch [sentry.io](http://sentry.io/) (dauerhaft)
- __stripe_mid zur Fehlerverfolgung und -protokollierung durch [sentry.io](http://sentry.io/) (dauerhaft)
- _ga zur Fehlerverfolgung und -protokollierung durch [sentry.io](http://sentry.io/) (dauerhaft)
- gsID zur Fehlerverfolgung und Protokollierung durch [sentry.io](http://sentry.io/) (Session)
- anonId zur Fehlerverfolgung und Protokollierung durch [sentry.io](http://sentry.io/) (Session)
- ai_authUser [z](http://app.signalznow.de/)ur Nutzungsstatistik in [app.signalznow.de](http://app.signalznow.de) (Session)
- ai_session  zur Nutzungsstatistik in [app.signalznow.de](http://app.signalznow.de) (dauerhaft)
- ai_user [z](http://app.signalznow.de/)ur Nutzungsstatistik in [app.signalznow.de](http://app.signalznow.de) (dauerhaft)

Benutzer können ihren Webbrowser so konfigurieren, dass notwendige Cookies blockiert werden, was dazu führen kann, dass sie die Funktionen von GetSignalz möglicherweise nicht wie vorgesehen nutzen können.

## Vorratsdatenspeicherung

Personenbezogene Daten werden von GetSignalz für den in unserem Vertrag mit dem Kunden vereinbarten Zeitraum aufbewahrt, sofern nicht anderweitig durch geltende Datenschutzgesetze erforderlich oder zulässig.

## Sicherheit der Datenverarbeitung

GetSignalz hat angemessene technische und organisatorische Maßnahmen gemäß den Anforderungen des Art. 32 DSGVO zum Schutz der personenbezogenen Daten der Nutzer. GetSignalz stellt sicher, dass alle seine Mitarbeiter und andere Personen, die zur Verarbeitung der personenbezogenen Daten berechtigt sind, sich zur Vertraulichkeit verpflichtet haben oder einer angemessenen gesetzlichen Verpflichtung zur Vertraulichkeit unterliegen.

## Verantwortlicher der personenbezogenen Daten

Der Verantwortliche im Sinne der DSGVO in Bezug auf die Verarbeitung der personenbezogenen Daten eines Benutzers im Zusammenhang mit der Bereitstellung der Dienste, einschließlich der Nutzung von GetSignalz durch einen Benutzer, ist der Kunde, der den jeweiligen Benutzer autorisiert hat, auf GetSignalz zuzugreifen.

## Rechtsgrundlage

Der Kunde ist dafür verantwortlich sicherzustellen, dass die Verarbeitung personenbezogener Daten im Zusammenhang mit der Bereitstellung der Dienste für diesen Kunden auf einer Rechtsgrundlage gemäß den geltenden Datenschutzgesetzen beruht. Die konkrete Rechtsgrundlage für die Datenverarbeitung (z. B. Vertragserfüllung oder berechtigtes Interesse des Kunden, GetSignalz für interne Geschäftszwecke zu nutzen) wird vom Kunden bestimmt.

## Rechte der betroffenen Person

Benutzern stehen bestimmte Rechte in Bezug auf die Verarbeitung ihrer personenbezogenen Daten zu. Insbesondere und vorbehaltlich der gesetzlichen Voraussetzungen können die Nutzer berechtigt sein

- Erhalten Sie eine Bestätigung darüber, ob personenbezogene Daten verarbeitet werden oder nicht, und falls dies der Fall ist, um (i) Informationen über die Verarbeitung einschließlich der Rechtsgrundlage zu erhalten und (ii) auf diese personenbezogenen Daten zuzugreifen;
- Fordern Sie die Korrektur unrichtiger personenbezogener Daten an;
- Fordern Sie die Löschung personenbezogener Daten an;
- Einschränkung der Datenverarbeitung verlangen;
- Datenübertragbarkeit in Bezug auf personenbezogene Daten anfordern, die ein Benutzer aktiv bereitgestellt hat, und;
- Aus Gründen, die sich aus der besonderen Situation des Benutzers ergeben, der weiteren Verarbeitung personenbezogener Daten widersprechen.

Anfragen zur Ausübung der Rechte müssen an den jeweiligen Kunden (Controller) gerichtet werden, für den GetSignalz die personenbezogenen Daten des jeweiligen Benutzers verarbeitet. Darüber hinaus können sich Nutzer bei datenschutzbezogenen Fragen, Beschwerden oder Anregungen jederzeit über [info@mapaliro.com](mailto:info@mapaliro.com) addressiert werden.
`
