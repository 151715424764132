import { customSchemaErrors, translate } from "@effect-app/vue/form"
import { createIntl, createIntlCache } from "@formatjs/intl"
import type { PrimitiveType, FormatXMLElementFn } from "intl-messageformat"

const messages = {
  de: {
    "handle.success": "{action} erfolgreich",
    "handle.with_errors": "{action} fehlgeschlagen",
    "handle.with_warnings": "{action} erfolgreich, mit Warnungen",
    "handle.error_response": "Die Anfrage war nicht erfolgreich:\n{error}",
    "handle.response_error":
      "Die Antwort konnte nicht verarbeitet werden:\n{error}",
    "handle.request_error":
      "Die Anfrage konnte nicht gesendet werden:\n{error}",
    "handle.unexpected_error": "Unerwarteter Fehler:\n{error}",
    "action.change_chart": "Chart ändern",
    "action.update_user": "Benutzer aktualisieren",
    "action.toggle_owned": "Security im Besitz",
    "action.subscribe_signal": "Signal abonnieren",
    "action.unsubscribe_signal": "Signal abbestellen",
    "action.add_to_favorites": "Zu Favoriten hinzufügen",
    "action.remove_from_favorites": "Von Favoriten entfernen",
    "action.add_security": "Security hinzufügen",
    "action.remove_security": "Security entfernen",
    "action.edit_security": "Security bearbeiten",
    "fieldnames.actions": "Aktionen",
    "fieldnames.isin": "ISIN",
    "fieldnames.name": "Name",
    "fieldnames.lastUpdate": "Letztes Update",
    "fieldnames.user": "Benutzer",
    "fieldnames.password": "Kennwort",
    "fieldnames.owned": "im Besitz",
    "fieldnames.owned.yes": "Ja",
    "fieldnames.owned.no": "Nein",
    "fieldnames.ticker": "Ticker",
    "signal.BUY": "Kauf",
    "signal.HOLD": "Halten",
    "signal.NONE": "Kein",
    "signal.SELL": "Verkauf",
    "signal.remove": "Signal entfernen?",
    "body.addNewSecurities": "Neue Wertpapiere hinzufügen",
    "body.addToPortfolio": "Zum Portfolio hinzufügen",
    "body.author": "Autor",
    "body.closeDialog": "Dialogfeld schließen",
    "body.create_ticket": "Erstellen",
    "body.country": "Land",
    "body.filter_security": "Nach Name / Symbol filtern",
    "body.home": "Startseite",
    "body.new": "Neu",
    "body.noMatch": "Keine Übereinstimmung gefunden",
    "body.noSecurities":
      'Sie haben derzeit kein Signal abonniert. Klicken Sie unten auf die Schaltfläche "Plus", um ein Wertpapier hinzuzufügen, für das Sie Signale abonnieren möchten.',
    "body.searchSecurity": "Suchen",
    "body.settings": "Einstellungen",
    "body.showOwned": 'Nur "im Besitz"',
    "body.back": "Zurück",
    "body.content": "Inhalt",
    "body.delete": "Löschen",
    "body.stockExchange": "Börse",
    "body.stockSymbol": "Börsenkürzel",
    "body.submit": "Speichern",
    // "body.terms": "Geschäftsbedingungen",
    "body.terms": "AGB",
    "body.ticket": "Ticket",
    "body.ticket_list": "Liste Tickets",
    "body.tickets": "Supporttickets",
    "body.title": "Titel",
    "body.verifyEmail": "Bitte bestätige deine Email Adresse.",
    "chart.note":
      "* Hinweis: Der Chart zeigt nur eine Annäherung der Signalperformance in Bezug auf die Preisgestaltung. Überprüfen Sie die tatsächliche kumulative Signalperformance unterhalb des Charts.",
    "header.myPortfolio": "Mein Portfolio",
    "header.proArea": "Pro-Bereich",
    "header.securities": "Wertpapiere",
    "security.filter.owned": "Nur im Besitz",
    "security.filter.watched": "Nur beobachtet",
    "security.not_found": "Das gesuchte Wertpapier nicht gefunden?",
    "signal.base_performance": "Basisperformance",
    "signal.long_term_performance": "Algo Lang",
    "signal.short_term_performance": "Algo Kurz",
    "signal.performance.header": "Signal Performance",
    "signal.performance.text":
      "Das dargestellte Diagramm zeigt die Signalperformance gegenüber der Aktienperformance. Die Signalperformance geht von einer Startinvestition von 100 Punkten und Verkauf und Kauf bei jeder Signaländerung bei voller Reinvestition der Rendite aus.",
    "signal.performance.preview": "Preview",
    "signal.performance.preview_unavailable": "Kein bild hochgeladen",
    "pro.no-securities": "Noch keine Securities verfügbar",
    "settings.email": "E-Mail",
    "settings.emailFrequency": "E-Mail-Häufigkeit",
    "settings.language": "Sprache",
    "settings.emailFrequency.never": "Niemals",
    "settings.emailFrequency.signalChange": "Bei Signalwechsel",
    "settings.emailFrequency.daily": "Täglich",
    "settings.role": "Rolle",
    "tos.accept": "Hiermit stimme ich den Nutzungsbedingungen zu.",
    "session.expired": "Deine Sitzung ist abgelaufen. Neu anmelden?",
    "terms_and_conditions.full": terms_de,
    "text.algorithm": "Algorithmus",
    "text.algorithm_name": "Algorithmus Name",
    "text.all": "Alle",
    "text.at": "am",
    "text.base_price": "Aktienkurs",
    "text.better": "besser",
    "text.buy_and_hold": "Kaufen & Halten",
    "text.close": "Geschlossen",
    "text.closed": "Geschlossen",
    "text.contact_us": "Kontaktiere uns",
    "text.five_years": "(5 Jahre)",
    "text.how_is_it_calculated": "Wie wird es berechnet?",
    "text.last": "Letztes",
    "text.latest_action_date": "Letzte Aktion empfohlen",
    "text.latest_recommended_action": "Letzte empfohlene Aktion",
    "text.months": "Monate",
    "text.most_recent_first": "Neueste zuerst",
    "text.no_signals": "Keine Signale für Wertpapier",
    "text.no_signals_or_security":
      "Wir konnten kein Wertpapier finden, das der Abfrage entspricht, oder wir bieten keine Signale dafür an",
    "text.oldest_first": "Älteste zuerst",
    "text.on": "am",
    "text.or": "oder",
    "text.one_year": "1 Jahr",
    "text.open": "Offen",
    "text.summary_hold": "Wenn Sie gekauft hätten",
    "text.summary_hold2": "und bis",
    "text.summary_hold3": "gehalten hät hätten Sie eine Performance von",
    "text.summary_perf": "TODO",
    "text.per_month": "pro Monat",
    "text.per_year": "pro Jahr",
    "text.support": "Support",
    "text.your_reply": "Deine Antwort",
    "text.worse": "schlechter",
    "text.written_on": "Geschrieben am",
    "text.ticket_created_successfully": "Ticket erfolgreich erstellt",
    "subscribe.offer_one.plan_name": "Free Plan",
    "subscribe.offer_one.plan_description":
      "Schau Dir alles an, teste unsere Algorithmen und überzeuge Dich von dem Mehrwert, den SignalzNow Dir bietet!",
    "subscribe.offer_two.plan_name": "Basic Plan",
    "subscribe.offer_two.plan_description":
      "Hole mehr aus Deinem Portfolio indem Du bis zu 11 Signale abonnierst und automatisch informiert wirst, wenn Du aktiv werden solltest.",
    "subscribe.offer_three.plan_name": "Pro Plan",
    "subscribe.offer_three.plan_description":
      "Streue Dein Risiko über mehr Aktien und ETF's und lass diese kontinuierlich analysieren. Du kannst bis zu 33 Signale abonnieren und Dich zurücklehnen.",
    "subscribe.offer_four.plan_name": "Guru Plan",
    "subscribe.offer_four.plan_description":
      "Go Big! Bis zu 111 Signale abonnieren und so richtig durchstarten. Erhalte volle Flexibilität beim Investieren und Beobachten interessanter Werte.",
    "subscribe.feature_one":
      "Sichtbarkeit aller Verläufe von Aktien und ETF Signalen",
    "subscribe.feature_two": "Benachrichtigungen für {num} Aktien oder ETF's",
    "validation.empty": `Das Feld darf nicht leer sein`,
    "validation.number.max":
      "Der Wert sollte {isExclusive, select, true {kleiner als} other {höchstens}} {maximum} sein",
    "validation.number.min": `Der Wert sollte {isExclusive, select, true {größer als} other {mindestens}} {minimum} sein`,
    "validation.string.maxLength": `Das Feld darf nicht mehr als {maxLength} Zeichen haben`,
    "validation.string.minLength": `Das Feld muss mindestens {minLength} Zeichen enthalten`,
    "validation.not_a_valid": `Der eingegebene Wert ist kein gültiger {type}: {message}`,
    "validation.failed": "Ungültige Eingabe",
  },
  en: {
    "handle.success": "{action} Success",
    "handle.with_errors": "{action} Failed",
    "handle.with_warnings": "{action}, with warnings",
    "handle.error_response":
      "There was an error in processing the response:\n{error}",
    "handle.request_error": "There was an error in the request:\n{error}",
    "handle.response_error": "The request was not successful:\n{error}",
    "handle.unexpected_error": "Unexpected Error:\n{error}",
    "action.update_user": "Update User",
    "action.add_to_favorites": "Add to Favorites",
    "action.remove_from_favorites": "Remove from Favorites",
    "action.subscribe_signal": "Subscribe Signal",
    "action.unsubscribe_signal": "Unsubscribe Signal",
    "action.toggle_owned": "Toggle Security Owned",
    "action.add_security": "Add Security",
    "action.remove_security": "Remove Security",
    "action.edit_security": "Edit Security",
    "action.change_chart": "Change Chart",
    "fieldnames.actions": "Actions",
    "fieldnames.isin": "ISIN",
    "fieldnames.name": "Name",
    "fieldnames.password": "Password",
    "fieldnames.lastUpdate": "Last update",
    "fieldnames.user": "User",
    "fieldnames.owned": "Currently in possession",
    "fieldnames.owned.yes": "Yes",
    "fieldnames.owned.no": "No",
    "fieldnames.ticker": "Ticker",
    "signal.BUY": "Buy",
    "signal.SELL": "Sell",
    "signal.NONE": "No action recommended",
    "signal.HOLD": "Hold",
    "signal.remove": "Remove the signal?",
    "body.addToPortfolio": "Add to Portfolio",
    "body.addNewSecurities": "Add new securities",
    "body.closeDialog": "Close Dialog",
    "body.country": "Country",
    "body.create_ticket": "Create ticket",
    "body.filter_security": "Filter by name / symbol",
    "body.home": "Home",
    "body.new": "New",
    "body.noMatch": "No match found",
    "body.noSecurities":
      'You are currently not subscribed to any signal. Press the "plus" button below to add a security you want to subscribe for signals for.',
    "body.searchSecurity": "Search security",
    "body.settings": "Settings",
    "body.showOwned": "Show Owned Only",
    "body.back": "Back",
    "body.content": "Content",
    "body.delete": "Delete",
    "body.author": "Author",
    "body.stockExchange": "Exchange",
    "body.stockSymbol": "Symbol",
    "body.submit": "Submit",
    "body.terms": "Terms & Conditions",
    "body.ticket_list": "Tickets list",
    "body.ticket": "Ticket",
    "body.tickets": "Tickets",
    "body.title": "Title",
    "body.verifyEmail": "Please verify your email address.",
    "chart.note":
      "* Note: the chart only displays an approximation of the signal performance in terms of pricing. Check the actual cumulative signal performance below the chart.",
    "header.myPortfolio": "My Portfolio",
    "header.proArea": "Pro Area",
    "header.securities": "Securities",
    "signal.base_performance": "Base",
    "signal.long_term_performance": "Long-Term Algo",
    "signal.short_term_performance": "Short-Term Algo",
    "signal.performance.header": "Signal Performance",
    "signal.performance.text":
      "The presented graph shows the signal performance versus the security performance. The signal performance assumes a starting investment of 100 points and selling and buying with each signal change at full reinvestment of return.",
    "signal.performance.preview": "Preview",
    "signal.performance.preview_unavailable": "No image uploaded.",
    "pro.no-securities": "No securities available",
    "settings.email": "Email",
    "settings.emailFrequency": "Email Frequency",
    "settings.language": "Language",
    "settings.emailFrequency.never": "Never",
    "settings.emailFrequency.signalChange": "Signal Change",
    "settings.emailFrequency.daily": "Daily",
    "settings.role": "Role",
    "security.filter.owned": "Owned only",
    "security.filter.watched": "Watched only",
    "security.not_found": "Can't find the security you're looking for?",
    "tos.accept": "Hereby I accept the terms.",
    "session.expired": "Your session has expired. Login again?",
    "terms_and_conditions.full": terms_en,
    "text.algorithm": "Algorithm",
    "text.algorithm_name": "Algorithm name",
    "text.all": "All",
    "text.at": "at",
    "text.buy_and_hold": "Buy & Hold",
    "text.base_price": "Price (close)",
    "text.better": "better",
    "text.contact_us": "Contact us",
    "text.close": "Closed",
    "text.five_years": "(5 years)",
    "text.how_is_it_calculated": "How is it calculated?",
    "text.last": "Last",
    "text.latest_action_date": "Latest action",
    "text.latest_recommended_action": "Latest recommended action",
    "text.months": "Months",
    "text.most_recent_first": "Most recent first",
    "text.no_signals": "No signals for security",
    "text.no_signals_or_security":
      "We couldn't find any security matching the query, or we don't offer signals for it",
    "text.oldest_first": "Oldest first",
    "text.on": "on",
    "text.or": "or",
    "text.one_year": "1 Year",
    "text.open": "Open",
    "text.summary_hold": "If you had bought",
    "text.summary_hold2": "and held till",
    "text.summary_hold3": "you would've had a performance of",
    "text.summary_perf":
      "If you had bought and sold {tickerName} according to the algorithm you would've performed {performancePercentage} % which would've been {relative} than using a Buy & Hold strategy.",
    "text.support": "Support",
    "text.your_reply": "Your reply",
    "text.per_month": "per month",
    "text.per_year": "per year",
    "text.worse": "worse",
    "text.written_on": "Written on",
    "text.ticket_created_successfully": "Ticket created successfully",
    "subscribe.offer_one.plan_name": "Free Plan",
    "subscribe.offer_one.plan_description":
      "Learn about SignalzNow, test our algorithms and get convinced about the benefit you can get from it",
    "subscribe.offer_two.plan_name": "Basic Plan",
    "subscribe.offer_two.plan_description":
      "Get more on your portfolio by subscribing to up to 10 signals and find out which securities the SignalzNow community invests in.",
    "subscribe.offer_three.plan_name": "Pro Plan",
    "subscribe.offer_three.plan_description":
      "Spread your risk across more stocks and ETFs and have them analyzed continuously. You can subscribe to up to 33 signals and sit back and relax.",
    "subscribe.offer_four.plan_name": "Guru Plan",
    "subscribe.offer_four.plan_description":
      "Go Big! Subscribe to up to 111 signals and get started. Get full flexibility when investing and monitoring interesting values.",
    "subscribe.feature_one": "Unlimited visibility of all available signals",
    "subscribe.feature_two": "Notifications for {num} securities or ETF's",
    "validation.empty": "The field cannot be empty",
    "validation.number.max":
      "The value should be {isExclusive, select, true {smaller than} other {at most}} {maximum}",
    "validation.number.min":
      "The value should be {isExclusive, select, true {larger than} other {at least}} {minimum}",
    "validation.string.maxLength":
      "The field cannot have more than {maxLength} characters",
    "validation.string.minLength":
      "The field requires at least {minLength} characters",
    "validation.not_a_valid":
      "The entered value is not a valid {type}: {message}",
    "validation.failed": "Invalid input",
  },
}

const intlCache = createIntlCache()

const intls = {
  de: createIntl(
    {
      defaultLocale: "en",
      locale: "de",
      messages: messages.de,
    },
    intlCache,
  ),
  en: createIntl(
    {
      defaultLocale: "en",
      locale: "en",
      messages: messages.en,
    },
    intlCache,
  ),
}

const u = useOptionalSessionUser()

export const locale = computed(() => u.value?.locale ?? "de")

export const trans = (
  id: keyof (typeof messages)["en"],
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
) => intls[locale.value].formatMessage({ id }, values)

export const intl = computed(() => intls[locale.value])
watch(
  locale,
  locale => {
    const intl = intls[locale]
    translate.value = intl.formatMessage.bind(intl)
    customSchemaErrors.value.set(
      "ISIN",
      locale === "de"
        ? () => "erwarte eine gültige ISIN wie 'US0378331005'"
        : () => "expected a valid ISIN like 'US0378331005'",
    )
    customSchemaErrors.value.set(
      "PasswordInput",
      locale === "de"
        ? () => "min 8, Großbuchstabe, Kleinbuchstabe, Zahl, Sonderzeichen"
        : () => "min 8, upper, lower, number, special char",
    )
  },
  { immediate: true },
)
